import { reqQuery } from "api/ReqResApi";
import { format } from "date-fns";

export const userQuery = () => JSON.parse(
    sessionStorage.getItem( 'userQuery' )
);

export const actualQuery = () => JSON.parse(
    sessionStorage.getItem( 'query' )
);

export const formatDateTime = ( dateTime ) =>{
   return format( new Date( dateTime ), 'yyyy-MM-dd HH:mm' )
}

export const localTime = ( event ) => {
    const time = new Date( event );
    const month = time.getMonth();
    const day = time.getDate();
    const hours = time.getHours();
    const minutes = time.getMinutes();
    return `${ time.getFullYear() }-${ month > 8 ? ( month + 1 ) : 0 + `${ month + 1 }` }-${ day > 9 ? day : 0 + `${ day }` } ${ hours > 9 ? hours : 0 + `${ hours }` }:${ minutes > 9 ? minutes : 0 + `${ minutes }` }`;
}

export const utcTime = ( event ) => {
    const time = new Date( event );
    const month = time.getUTCMonth();
    const day = time.getUTCDate();
    const hours = time.getUTCHours();
    const minutes = time.getUTCMinutes();
    return `${ time.getFullYear() }-${ month > 9 ? ( month + 1 ) : 0 + `${ month + 1 }` }-${ day > 9 ? day : 0 + `${ day }` } ${ hours > 9 ? hours : 0 + `${ hours }` }:${ minutes > 9 ? minutes : 0 + `${ minutes }` }`;
}

export const QTotalRequest = async ( form ) => {
    try {
        const { data } = await reqQuery
            .post( 'api/search/count', form );
        return data ? data.count[5] : false;
    } catch { return false }
}

const buildTensor = event => [
    "moment_tensor_swift",
    "moment_tensor_scmtv",
    "moment_tensor_phasew",
    "moment_tensor_isola_solution"
].filter ( item => Object.keys( event ).find( key => key === item ));

const transformDecimal = value => value.toFixed( 1 );

const defineValue = ( value, trigger ) => value ? trigger( value ) : ''

const defineStatus = value => value === 'manual' ? 'manual' : 'automatic';

const validateValue = value => {
   if (value !== undefined && value !== null){
      return value;
   }
   return null;
}

const filterLocation = (lat, lng) => {
   return (lat >= -4 && lat <= 14) && (lng >= -82 && lng <= -66);
}

const filterHits = ( hit, currentId ) => {
   // To do: check api validations. These front-end validations are provisional.
   if ( currentId === hit._source.public_id ) return false; // to valid repeated ids
   if ( hit._source.origin_evaluation_mode !== "manual" ) return false; // to hide automatics status
   if ( hit._source.type_event === "not existing" ) return false; // to hide not existing events
   
   return filterLocation( hit._source.location[1], hit._source.location[0] );
}

export const QRequest = async form => {
    try {
         let currentId = "";
        const { data } = await reqQuery.post( 'api/search', form );
        return !data
            ? false
            : data.result[5].hits.hits.filter( hit => {
               let valid = filterHits( hit, currentId );
               currentId = hit._source.public_id;
               return valid;
            } ).map( hit => {
                return {
                    type: "Feature",
                    id: hit._source.public_id,
                    properties: {
                        status: defineValue( hit._source.origin_evaluation_mode, defineStatus ),
                        rms: defineValue( hit._source.origin_quality_standard_error, transformDecimal ),
                        updated: hit._source.last_modified,
                        felt: 0,
                        sm: hit._source.peak_ground_acceleration !== undefined && hit._source.peak_ground_acceleration !== null,
                        type: "earthquake",
                        magType: hit._source.magnitude_type,
                        agency: hit._source.origin_agency_id,
                        // cdi: hit._source.shake_map
                        //     ? Math.floor( hit._source.shake_map[0].value )
                        //     : 0,
                        closerTowns: hit._source.closer_towns,
                        gap: hit._source.origin_quality_azimuthal_gap,
                        utcTime: formatDateTime( hit._source.origin_time ),
                        localTime: formatDateTime( hit._source.cal_local_time ),
                        place: hit._source.description,
                        mag: hit._source.magnitude.toFixed(1),
                        mmi: validateValue( hit._source.cal_shake_map ),
                        maxPGA: hit._source.cal_acceleration || '',
                        beachBall: hit._source.cal_solution_moment_tensor || '',
                        magnitudeError: hit._source.magnitude_uncertainty,
                        depthError : hit._source.origin_depth_uncertainty,
                        longitudeError: hit._source.origin_longitude_uncertainty,
                        latitudeError: hit._source.origin_latitude_uncertainty,
                        tensorMoment: buildTensor( hit._source ),
                        catalog: hit._source.catalog,
                        phases: hit._source.origin_quality_associated_phase_count,
                        // tensorMoment: hit.inner_hits ? buildTensor( hit.inner_hits ) : []
                    }, geometry: {
                        type: "Point",
                        coordinates: [
                            hit._source.location[1],
                            hit._source.location[0],
                            hit._source.origin_depth.toFixed(1)
                        ]
                    }, moreInfo: null,
                }
            })
    } catch
    {
        return false
    }
}